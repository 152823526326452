












import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
    name: 'Popconfirm',
    components: {}
})
export default class Popconfirm extends Vue {
    @Prop({
        type: String,
        default: ''
    })
    public text: String;

    @Prop({
        type: String,
        default: ''
    })
    public title: String;

    public submit() {
        this.$emit('submit');
    }
}
