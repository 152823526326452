





import { Component, Vue } from 'vue-property-decorator';

@Component({
    name: 'Printer',
    components: {}
})
export default class Printer extends Vue {

    public printer() {
        this.PrintForm('printId');
        // this.$emit('printer');
    }

    PrintForm(id) {
        // 空页面
        let printStr = "<html><head><meta http-equiv='Content-Type' content='text/html; charset=utf-8'></head>";
        // 定义element-ui table组件的样式
        const tabStyle = `<style>
            table{width:100%;display:table-cell!important;box-sizing:border-box;}
            .el-table__header,.el-table__body,.el-table__footer{width:100%!important;border-collapse: collapse;text-align:center;}
            table,table tr th, table tr td { border:1px solid #ddd;color:#606266;word-wrap:break-word}
            table tr th,table tr td{padding:5 10px;word-wrap:break-word }
            .el-table__body, tr td .cell{width:100%!important}
            .el-table th.gutter{display: none;}
            .el-table colgroup.gutter{display: none;}
            .el-pagination { display: none; }
            </style><body>`;
        let content = '';
        // 获取名为传入id的 dom元素内的内容
        const html = document.getElementById(id).innerHTML;
        // 新建一个 DOM
        const div = document.createElement('div');
        const printDOMID = 'printDOMElement';
        div.id = printDOMID;
        div.innerHTML = html;

        // elment ui 的表格中表头与内容是用的两个表格去渲染的 所以这里要做拼接

        // 提取第一个表格的内容 即表头
        const ths: any = div.querySelectorAll('.el-table__header-wrapper th');
        const ThsTextArry = [];
        for (let i = 0, len = ths.length; i < len; i++) {
            if (ths[i].innerText !== '') { ThsTextArry.push(ths[i].innerText); }
        }

        // element ui 第一列 删除（列如多选）
        const rowTd: any = div.querySelectorAll('.el-table__row');
        for (let i = 0; i < rowTd.length; i++) {
            const row: any = rowTd[i];
            row.firstChild.remove();
        }

        // 删除多余的表头
        div.querySelector('.hidden-columns').remove();
        // 第一个表格的内容提取出来后已经没用了 删掉
        div.querySelector('.el-table__header-wrapper').remove();

        // 将第一个表格的内容插入到第二个表格
        let newHTML = '<tr>';
        for (let i = 0, len = ThsTextArry.length; i < len; i++) {
            newHTML +=
            '<td style="text-align: center; font-weight: bold">' +
            ThsTextArry[i] +
            '</td>';
        }

        newHTML += '</tr>';
        div.querySelector('.el-table__body-wrapper table').insertAdjacentHTML('afterbegin', newHTML);
        const str = div.innerHTML;

        // 拼接空页面+style样式+dom内容
        content = content + str;
        printStr = printStr + tabStyle + content + '</body></html>';
        // 打开新页面
        const pWin = window.open('_blank');
        // 将内容赋值到新页面
        pWin.document.write(printStr);
        // 使用setTimeout，等页面dom元素渲染完成后再打印。
        setTimeout(() => {
            pWin.print(); // 调用打印功能。
            pWin.close(); // 关闭 打印创建的当前页面
        }, 300);
    }

}
