import request from '@/utils/request';
/*
获取自提点订单列表
GET /Order/GetList
接口ID：44498206
接口地址：https://www.apifox.cn/web/project/1774034/apis/api-44498206

*/
export const OrderGetList = (params: any) =>
    request({
        url: '/DeliveryStation/OrderWaybill/GetList',
        method: 'GET',
        params
    });

/*
导出
GET /OrderWaybill/DownloadExcel
接口ID：44559909
接口地址：https://www.apifox.cn/web/project/1774034/apis/api-44559909
*/
export const OrderWaybillDownloadExcel = (params: any) =>
    request({
        url: '/DeliveryStation/OrderWaybill/DownloadExcel',
        method: 'GET',
        params
    });

/*
运单到件操作
POST /OrderWaybill/OrderWaybillArrive
接口ID：44652185
接口地址：https://www.apifox.cn/web/project/1774034/apis/api-44652185
*/
export const OrderWaybillOrderWaybillArrive = (params: any) =>
    request({
        url: '/DeliveryStation/OrderWaybill/OrderWaybillArrive',
        method: 'POST',
        params
    });

/*
运单签收操作
POST /OrderWaybill/OrderWaybillReceipt
接口ID：44652186
接口地址：https://www.apifox.cn/web/project/1774034/apis/api-44652186
*/
export const OrderWaybillOrderWaybillReceipt = (params: any) =>
    request({
        url: '/DeliveryStation/OrderWaybill/OrderWaybillReceipt',
        method: 'POST',
        params
    });
