


































import { Component, Vue, Ref } from 'vue-property-decorator';
import YwhzDatePicker from '@/components/YwhzDateCard/daterange-picker.vue';
import YwhzPopconfirm from './components/popconfirm.vue';
import YwhzPrinter from './components/printer.vue';
import YwhzExport from './components/export.vue';

import headerConfig from '@/assets/table.json';
import { ConditionalTypeEnum } from '@/utils/search';
import { messageTit } from '@/utils/tool';

import { OrderGetList, OrderWaybillOrderWaybillArrive, OrderWaybillOrderWaybillReceipt } from '@/api/api';
@Component({
    name: 'CarryManagement',
    components: {
        YwhzPopconfirm,
        YwhzPrinter,
        YwhzExport
    }
})
export default class CarryManagement extends Vue {
    @Ref('search') private readonly searchRef!: any;
    @Ref('table') private readonly tableRef!: any;

    public headerColumns = (headerConfig as any)[this.$route?.name || ''] || [];

    // 多选，选中
    public selectList: any = [];

    public searchAttrs: any = [
        {
            label: '發貨時間：',
            prop: 'CustomCreateTime',
            component: YwhzDatePicker,
            attrs: {
                clearable: true,
                placeholder: '請輸入關鍵字查詢'
            },
            query: {
                type: ConditionalTypeEnum['等于']
            }
        }
    ];

    public listConfig = {
        constructor: OrderGetList,
        formatter: (source: any) => {
            source.map((item) => {
                item.customDisabled = true;
                return item;
            });
            return source;
        }
    };

    private mounted() {
        this.init();
    }

    public init() {
        const table = this.tableRef;
        const params = this.getParams();
        this.$nextTick(() => {
            table.init(params);
        });
    }

    // 获取参数
    private getParams(parmas: any = {}) {
        const search = this.searchRef;
        const table = this.tableRef;
        const searchParams: Record<string, any> = search.searchChange();
        const { page, size } = table.pagination;
        parmas.PageIndex = page;
        parmas.PageSize = size;
        parmas.OrderBy = 'CreateTime desc';
        if (searchParams.length) {
            parmas.Expressionable = searchParams;
        }
        return parmas;
    }

    public async pieces(type) {
        if (this.selectList.length === 0) {
            messageTit({
                message: '请选择包裹'
            });
            return;
        }

        const active = type === 1
        ? OrderWaybillOrderWaybillArrive
        : OrderWaybillOrderWaybillReceipt;

        try {
            const arrParmas: any = [];
            this.selectList.forEach(item => {
                arrParmas.push(item.WaybillNo);
            });
            await active({ OrderWaybillNos: arrParmas.join(',') });
            messageTit({
                message: '操作成功'
            });
            this.selectList = [];
            this.init();
        } catch (err) {
            console.warn(err);
        }

        console.log(type);
    }
}
