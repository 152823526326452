














import { Component, Prop, Vue } from 'vue-property-decorator';

const dateTime = (picker, day: number) => {
    const date: any = new Date();
    const start = date.getTime() - (24 * 60 * 60 * 1000) * day;
    picker.$emit('pick', [start, date]);
};

@Component({
    name: 'DaterangePicker',
    components: {}
})
export default class DaterangePicker extends Vue {
    @Prop({
        type: [Number, String, Array, Date],
        default: ''
    })
    public value!:any;

    public pickerOptions: any = {
        shortcuts: [
            {
                text: '今天',
                onClick(picker) {
                    const date: any = new Date();
                    picker.$emit('pick', [date, date.getTime() + 24 * 60 * 60 * 1000]);
                }
            },
            {
                text: '昨天',
                onClick(picker) {
                    dateTime(picker, 1);
                }
            },
            {
                text: '三天',
                onClick(picker) {
                    dateTime(picker, 3);
                }
            },
            {
                text: '一周',
                onClick(picker) {
                    dateTime(picker, 7);
                }
            },
            {
                text: '一月',
                onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setMonth(start.getMonth() - 1);
                    picker.$emit('pick', [start, end]);
                }
            }
        ]
    };

}
