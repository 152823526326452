






import { Component, Vue } from 'vue-property-decorator';

import { OrderWaybillDownloadExcel } from '@/api/api';

@Component({
    name: 'YwhzExportCard',
    components: {}
})
export default class YwhzExportCard extends Vue {

    public loading: boolean = false;

    public async exportExcel() {
        try {
            this.loading = true;
            const parent: any = this.$parent;
            const params = parent.getParams();
            const { Data } = await OrderWaybillDownloadExcel(params);
            await this.download(Data);
        } catch (err) {
            console.warn(err);
        } finally {
            this.loading = false;
        }
    }

    private download(file) {
        try {
            const url = process.env.VUE_APP_BASE_URL + '/DeliveryStation' + file;
            const a = document.createElement('a');
            const event = new MouseEvent('click');
            a.href = url;
            // a.download = fileName;
            a.dispatchEvent(event); // 释放url
        } catch (err) {
            console.warn(err);
        }
    }
}
